<template>
    <div>
        <nav class="d-flex justify-content-between align-items-center px-5" :class="{sticky: scrollY > 48}">
            <router-link :to="{ name: 'Home' }" class="navbar-brand">Portfolio</router-link>
            <slot />
            <ToTop v-show="scrollY > 400" />
        </nav>
        <nav v-show="scrollY > 48"></nav>
    </div>
</template>

<script>
import ToTop from './ToTop.vue'
export default {
    components: { ToTop },
    data() {
        return {
            scrollY: 0
        }
    },
    mounted() {
        window.addEventListener('scroll', () => this.scrollY = window.top.scrollY)
    }
}
</script>

<style scoped>
nav {
    height: 80px;
    transition: all .3s ease-in-out;
}

nav.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 58px;
    background-color: var(--bs-light);
    z-index: 1;
}

.navbar-brand {
    font-size: 1.8em;
    color: var(--bs-blue);
}

.mobile {
    display: none !important;
}

@media only screen and (max-width: 600px) {
    .mobile {
        display: block !important;
    }

    .desktop {
        display: none !important;
    }

    .px-5 {
        padding: 0 1em !important;
    }
}
</style>
import { render, staticRenderFns } from "./ToTop.vue?vue&type=template&id=f0396866&scoped=true&"
import script from "./ToTop.vue?vue&type=script&lang=js&"
export * from "./ToTop.vue?vue&type=script&lang=js&"
import style0 from "./ToTop.vue?vue&type=style&index=0&id=f0396866&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0396866",
  null
  
)

export default component.exports
<template>
  <div class="text">
    <p id="bio" class="mb-5">{{ text }}</p>
    <div class="cv-gh">
      <a :href="resume_link" target="_blank">Download Resume</a>
      <a href="https://github.com/IbrahimElOuadifi" target="_blank"
        >GitHube Profile</a
      >
    </div>
  </div>
</template>

<script>
import firebase from '../../firebase'
export default {
  props: ["text"],
  data() {
    return {
      resume_link: ''
    }
  },
  mounted() {
    firebase.storage().ref('resume.pdf').getDownloadURL()
      .then(url => this.resume_link = url)
      .catch(err => console.error(err))
  }
};
</script>

<style scoped>
section .content .text {
  max-width: 800px;
  text-align: center;
  padding: 48px 18px;
}

section .content .cv-gh {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 19px;
  margin: 0;
}

section .content .cv-gh a {
  color: var(--bs-blue);
}

section .content .cv-gh a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  section p {
    padding: 16px 0;
    font-size: 14px;
  }
}
</style>
<template>
  <div class="edu">
    <h3>{{ title }}</h3>
    <div id="timeline-edu">
      <TimeLineWrapper
        v-for="(item, index) in data"
        :key="index"
        :data="item.data()"
      />
    </div>
  </div>
</template>

<script>
import TimeLineWrapper from "@/components/about/TimeLineWrapper";
export default {
  props: ["title", "data"],
  components: { TimeLineWrapper },
};
</script>
<template>
  <section id="services">
    <div class="title"><h1>SERVICES</h1></div>
    <div class="content">
      <Item
        v-for="(service, index) in services"
        :key="index"
        :service="service.data()"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Item from "@/components/services/Item";
export default {
  components: { Item },
  computed: mapState({
    services: state => state.services,
  })
};
</script>

<style scoped>

@media only screen and (max-width: 800px) {
  section .content {
    flex-direction: column;
  }
}
</style>
<template>
  <div class="timeline-wrapper">
    <div class="timeline-yr" :title="getDate()">
      <span>{{ getYear() }}</span>
    </div>
    <div class="timeline-info">
      <h3>
        <span>{{ data.title }}</span>
      </h3>
      <small
        >{{ data.local }} | <i class="fa fa-map-marker"></i>
        {{ data.location }}</small
      >
      <p>{{ data.text }}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ["data"],
  methods: {
    getYear() {
      return this.data.to ? moment(this.data.to, 'YYYY/MM/DD').format('YYYY') : moment(this.data.from, 'YYYY/MM/DD').format('YYYY')
    },
    getDate() {
      const from = this.data.from ? moment(this.data.from, 'YYYY/MM/DD').format('MMM YYYY') : null
      const to = this.data.to ? moment(this.data.to, 'YYYY/MM/DD').format('MMM YYYY') : moment().format('MMM YYYY')
      return this.data.from ? `${from} - ${to}` : to
    }
  }
};
</script>

<style scoped>
.timeline-wrapper {
  position: relative;
  padding: 22px 0;
}
.timeline-wrapper:last-child:before {
  height: 0;
}
.timeline-wrapper:before {
  content: "";
  background: var(--bs-blue);
  width: 3px;
  height: 100%;
  position: absolute;
  left: 38px;
}

.timeline-yr {
  cursor: default;
  background: var(--bs-blue);
  border-radius: 100%;
  position: absolute;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
}

.timeline-yr span {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: block;
  line-height: 75px;
}

.timeline-info {
  display: inline-block;
  vertical-align: top;
  width: 480px;
  margin-left: 6em;
}

.timeline-info small {
  color: var(--bs-green);
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .timeline-info {
    width: 60vw;
  }
}

@media only screen and (max-width: 600px) {
  section .timeline-info h3 {
    font-size: 19px;
  }
}
</style>
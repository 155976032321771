<template>
  <div class="item">
    <div class="box">
      <i :class="'fa fa-' + service.fa_icon"></i>
      <h3>{{ service.title }}</h3>
      <p>{{ service.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service"],
};
</script>

<style scoped>
section .item {
  cursor: default;
  text-align: center;
  padding: 8px 14px;
  margin: 16px;
  box-shadow: var(--bs-gray) 0 0 8px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

section .item:hover {
  box-shadow: var(--bs-gray) 0 0 8px;
  background-color: var(--bs-blue);
}

section .item .box {
  transition: all 0.3s ease-in-out;
}

section .item:hover .box {
  transform: scale(1.025);
}

section .item i {
  font-size: 48px;
  margin: 12px;
  color: var(--bs-blue);
}

section .item:hover i,
section .item:hover h3,
section .item:hover p {
  color: var(--bs-white);
}

@media only screen and (max-width: 800px) {
  section .item h3 {
    font-size: 18px;
  }

  section .item p {
    font-size: 14px;
  }
}
</style>
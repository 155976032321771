<template>
    <div id="totop" @click="toTop">
      <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </div>
</template>

<script>
export default {
    methods: {
        toTop() {
            window.scrollTo(0, 0)
        }
    }
}
</script>

<style scoped>
#totop {
    display: flex;
    position: fixed;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: var(--bs-blue);
    color: var(--bs-light);
    bottom: 48px;
    right: 48px;
    border-radius: 50%;
    font-size: 28px;
    transition: all 0.3s ease-in;
    cursor: pointer;
    z-index: 2;
}

#totop:hover,
#totop:active {
    box-shadow: var(--bs-blue) 0 0 8px;
}

#totop i {
    cursor: pointer;
}
@media only screen and (max-width: 800px) {
    #totop {
        bottom: 50px;
        right: 28px;
        font-size: 32px;
    }
}
</style>
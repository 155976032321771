<template>
  <section id="works" class="my-5">
    <div class="title"><h1>WORKS</h1></div>
    <div class="content">
      <Item
        v-for="(project, index) in projects"
        :key="index"
        :index="index"
        :project="project.data()"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Item from "@/components/projects/Item";
export default {
  components: { Item },
  computed: mapState({
    projects: state => state.projects,
  })
};
</script>

<style scoped>
section .content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 600px) {
  section .content {
    padding: 2em 0 !important;
  }
}
</style>
<template>
  <div class="card bg-primary mb-3" @click="$router.push({ name: 'HomeWork', params: { project } })">
    <div class="card-body p-1">
      <img :src="project.img_url" />
      <div class="overlay"></div>
      <div class="hidden">
        <span
          v-for="(techno, index) in project.technos"
          :key="index"
          class="badge rounded-pill bg-dark m-1"
          :data-key="index"
        >
          {{ techno }}
          </span>
      </div>
    </div>
    <div class="card-header">{{ project.title }}</div>
  </div>
</template>

<script>
export default {
  props: ["project", 'index']
};
</script>

<style scoped>
.card {
  cursor: pointer;
  margin: 0.7em;
  border-radius: 4px;
  box-shadow: 8px 8px 8px 1px var(--bs-gray);
  width: 30%;
}

.card-header {
  color: #fff;
}

.card-body {
  position: relative;
}

.card img {
  border-radius: 4px;
  width: 100%;
}

.card .overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100%;
  background-color: var(--bs-blue);
  opacity: .4;
  transition: all 0.5s ease-in-out;
}

.card .hidden{
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .3s;
}

.card:hover .overlay {
  bottom: 0;
}

.card:hover .hidden {
  opacity: 1;
  transition: opacity .2s .5s;
}

.card .hidden .badge {
  opacity: 0;
  transform: translateY(-16px);
  transition: all .3s ease-in-out;
}

.card:hover .hidden .badge {
  opacity: 1;
  transform: translateY(0);
}

.card:hover .hidden .badge:nth-child(1) {
  transition: all .3s ease-in-out .6s;
}

.card:hover .hidden .badge:nth-child(2) {
  transition: all .3s ease-in-out .7s;
}

.card:hover .hidden .badge:nth-child(3) {
  transition: all .3s ease-in-out .8s;
}

.card:hover .hidden .badge:nth-child(4) {
  transition: all .3s ease-in-out .9s;
}

.card:hover .hidden .badge:nth-child(5) {
  transition: all .3s ease-in-out 1s;
}

.card:hover .hidden .badge:nth-child(6) {
  transition: all .3s ease-in-out 1.1s;
}

.card:hover .hidden .badge:nth-child(7) {
  transition: all .3s ease-in-out 1.2s;
}

.card:hover .hidden .badge:nth-child(8) {
  transition: all .3s ease-in-out 1.3s;
}

.card:hover .hidden .badge:nth-child(9) {
  transition: all .3s ease-in-out 1.4s;
}

.card:hover .hidden .badge:nth-child(10) {
  transition: all .3s ease-in-out 1.5s;
}

@media only screen and (max-width: 800px) {
  .card {
    width: 80%;
  }
}
</style>
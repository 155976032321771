<template>
  <section id="about">
    <div class="title"><h1>ABOUT</h1></div>
    <div class="content">
      <Descrip :text="description" />
      <div class="timeline">
        <TimeLine title="Education" :data="education" />
        <TimeLine title="Experience" :data="experience" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import Descrip from "@/components/about/Descrip";
import TimeLine from "@/components/about/TimeLine";
export default {
  components: { Descrip, TimeLine },
  computed: mapState({
    description: state => state.infos?.intro,
    education: state => state.education,
    experience: state => state.experience,
  })
};
</script>

<style scoped>
section .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section .timeline {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 86%;
}
</style>
<template>
    <div class="nav-mobile d-flex justify-content-center align-items-center" :class="{opend: $store.state.menu}">
      <div class="close-btn" @click="$store.commit('Menu', false)">
        <i class="fa fa-chevron-left"></i>
      </div>
      <ul>
        <li><a @click="$store.commit('Menu', false)" href="#about">About</a></li>
        <li><a @click="$store.commit('Menu', false)" href="#works">Works</a></li>
        <li><a @click="$store.commit('Menu', false)" href="#services">Services</a></li>
        <li class="nav-btn">
          <a @click="contact" class="btn btn-primary">CONTACT</a>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  methods: {
    contact() {
      this.$store.commit('Menu', false)
      this.$router.push({ name: 'HomeContact' })

    }
  }
}
</script>

<style scoped>
.nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bs-light);
  width: 100%;
  height: 100%;
  z-index: 5;
  left: -100%;
  transition: all 0.34s ease-in-out;
}

.nav-mobile.opend {
  left: 0;
}

.nav-mobile ul {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
}

li a {
  font-size: 1.2em;
}

.nav-mobile .nav-btn a {
  cursor: pointer;
  font-size: .9em;
  background-color: var(--bs-blue);
  color: #fff;
  padding: 8px 20px;
  border-radius: 8px;
}

.nav .nav-btn a:hover,
.nav-mobile .nav-btn a:hover {
  background-color: var(--bs-light);
  color: var(--bs-blue);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.close-btn i {
  font-size: 2.8em;
  padding: 24px;
  color: var(--bs-dark);
  transition: all 0.3s ease-in-out;
}

.close-btn:hover i {
  color: var(--bs-blue);
}
</style>